import React, {useState} from 'react'
import styled from 'styled-components'
import 'typeface-roboto'
import Navigation from '../Navigation'
import Box from './../../components/Box'
import Flex from './../../components/Flex'
import ContactInfo from '../ContactInfo'
import Header from './Header'
import hamburgerSrc from '../../images/hamburger.png'

const MenuWrapper = styled(Box)`
  height: ${props => props.menuOpen ? '45vh' : 0 };
  opacity: ${props => props.menuOpen ? 1 : 0 };
  transition: 200ms linear;
`

const MenuImg = styled.img`
  width: 100%;
`

const Layout = ({children}) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleOnClick =()=>{
    setMenuOpen(!menuOpen)
  }

  return (
    <Box position='relative' justifyContent='space-between'>
      <MenuWrapper menuOpen={menuOpen} p={menuOpen ? '32px 16px' : '0'}>
        {menuOpen && (
          <>
            <Navigation />
            <ContactInfo />
          </>
        )}
      </MenuWrapper>
      <Flex px='16px' alignItems='center' justifyContent={'space-between'}>
        <Header />
        <Flex py='32px' width='32px'  alignItems='center' onClick={handleOnClick}>
          <MenuImg src={hamburgerSrc} />
        </Flex>
      </Flex>
      {children}
    </Box>
  )
}

export default Layout