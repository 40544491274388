import React, { useState, useEffect } from 'react'
import Box from '../../components/Box'
import LinkInNav from './LinkInNav'
import GroupWrapper from './GroupWrapper'
import { globalHistory } from '@reach/router'

export const Group = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  
  const onNavClick = ()=>{
    setIsExpanded(!isExpanded)
  }

  const path = globalHistory.location.pathname

  useEffect(()=>{
    if(props.pages.find(page => page.link === path)){
      setIsExpanded(true)
    }
  }, [])

  //TODO fix animation blinking
  return (
    <Box>
      <GroupWrapper expanded={isExpanded} noAnimation={false} onNavClick={onNavClick} title={props.groupTitle}>
        {props.pages.map(page => {
          return <LinkInNav my={['16px', '16px', '12px']} key={page.link} active={path === page.link} to={page.link}>{page.title}</LinkInNav>
        })}
      </GroupWrapper>
    </Box>
  )
}

export default Group
