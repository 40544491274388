const palette = {
  yellow: '#fdfcc9',
  yellow09: 'rgba(253, 252, 201, 0.9)',
  white: '#fff',
  red: '#d74200',
  black: '#050202',
  blue: '#236fec',
  grey: '#bbbbbb',
  orange: '#D74200',
  lightGrey: 'rgba(22 , 22, 22, 0.3)'
}

const breakpoint = {
  xlg: '1350px',
  lg: '1200px',
  m: '750px',
  s: '700px',
  xs: '550px',
  xxs: '400px',
}

const fontSize = {
  xs: '1.5rem',
  s: '1.7rem',
  m: '2.5rem',
  l: '2.9rem',
  xl: '3.8rem',
  xxl: '4.6rem',
  xxxl: ' 10rem'
}

const font = {
  primary: 'Sorts Mill Goudy, serif',
  secondary: '\'Ultra\', serif'
}

const zIndex = {
  icon: '3000',
  menu: '500',
  landing: '10',
  landing_title: '20',
  navbar__wrap: '2000',
  services__pic: '5',
  portfolioCard__number: '2',
  portfolioModal: '30',
  privacyModal: '4000',
  portfolioModal__slick: '20',
  closeModal: '5000',
}

const theme = {
  palette,
  breakpoint,
  fontSize,
  font,
  zIndex
}

export default theme