import React from 'react'
import Box from '../components/Box'

const Caption = ({image})=>{
  if(!image) return null
  const {title, description} = image

  const getCleanDescription =  desc =>{
    const descArray =  desc.split('|')
    if(desc.trim()[0]==='|'){
      return descArray.slice(2).join('')
    }
    return descArray.join('')
  }

  return <Box mt='32px' mb='64px'>
    <Box pb='16px'>
      {title}
    </Box> 
    <Box color='#e2ddde' width='240px'>
      {getCleanDescription(description)}
    </Box>
  </Box>
}
export default Caption