import React from 'react'
import Navigation from './Navigation'
import { graphql, useStaticQuery } from 'gatsby'
import { paramCase } from 'param-case'

const NavigationWrapper = () => {
  const query = graphql`
  query {
    contentfulMenuStructure {
      menuStructure
      galleryItems {
        pageName
        isHomePage
      }
      groupItems {
      pageName
      pageLinks {
        ... on ContentfulPageWithGallery {
          isHomePage
          pageName
        }
				... on ContentfulPageWithSlider {
          isHomePage
          pageName
        }
      }
    }
      sliderItems {
        pageName
        isHomePage
      }
    }
  }
  
  `
  const {galleryItems, groupItems, sliderItems} = useStaticQuery(query).contentfulMenuStructure

  const mapPageGroups = (group)=> {
    if(!group){
      return []
    }

    return group.filter(g => !g.pageName.includes('Do Not Delete')).map(group => {
      return(
        {
          type: 'group',
          pages: group.pageLinks.map( page => ({title: page.pageName, link: page.isHomePage ? '/' : `/${paramCase(page.pageName)}`})),
          groupTitle: group.pageName
        })}
    )}

  const mapPages = (pages)=> pages.map(page => (
    {
      type: 'page',
      title: page.pageName,
      link: page.isHomePage ? '/' : `/${paramCase(page.pageName)}`
    })
  )

  const items = mapPages([ ...(sliderItems || []), ...(galleryItems || [])]
    .sort((a) => a.isHomePage ? -1 : 1))
    .concat(mapPageGroups(groupItems || []))

  return <Navigation items={items} />
}

export default NavigationWrapper
