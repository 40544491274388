import React from 'react'
import Box from '../../components/Box'
import styled from 'styled-components'

const GroupContainer = styled(Box)`
  height: ${props => props.expanded ? (props.numberOfChildren * 40): 0}px;
  overflow: hidden;
  ${props => !props.noAnimation && 'transition: 100ms ease-in-out;'};
  @media (max-width: 960px){
    height: ${props => props.expanded ? (props.numberOfChildren * 48): 0}px;
  }
`
const GroupWrapper = ({title, expanded, noAnimation, onNavClick, children})=>{
  return (
    <Box mb={expanded ? '0' : ['16px', '16px', '12px']}>
      <Box cursor='pointer' onClick={onNavClick}>{title}</Box>
      <GroupContainer numberOfChildren={children.length} noAnimation={noAnimation} expanded={expanded} pl='16px'>
        {children}
      </GroupContainer>
    </Box>
  )
}

export default GroupWrapper