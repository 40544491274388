import React from 'react'
import Box from '../components/Box'
import Flex from '../components/Flex'
import Img from '../components/Img'
import { ExternalLink } from './Navigation/LinkInNav'
import instagram from '../images/instagram.png'
import { graphql, useStaticQuery } from 'gatsby'


const contactInfo = graphql`
query{
  contentfulContactData{
    instagramLink
    email
  }
}
`
const ContactInfo = (props) => {
  const contactData = useStaticQuery(contactInfo)
  return (
    <Box {...props}>
      <Box mt={['24px','24px','32px']} fontSize={['12px', '12px', '10px']} color='#8a8a8a' />
      <Flex alignItems='flex-end'>
        <Box pr='8px' height='16px' width='16px' mt='16px' color='#8a8a8a'>
          <a target='blank' href={contactData.contentfulContactData.instagramLink}>
            <Img src={instagram} />
          </a>
        </Box>
        <Box borderLeft='1px solid black' pl='8px' mt='16px' color='#8a8a8a'>
          <ExternalLink target='blank' href={`mailto:${contactData.contentfulContactData.email}`}>
            {contactData.contentfulContactData.email}
          </ExternalLink>
        </Box>
      </Flex>
    </Box>
  )
}

export default ContactInfo