import styled, {css} from 'styled-components'
import Link from '../../components/Link'
import { spacing } from '@material-ui/system'

const styles = css`
  display: block;
  color: ${props => props.active ? '#e2ddde' : '#000'};
  text-decoration: ${props => props.underline ? 'underline' : 'none'};
`
const tightLetters = css`
  letter-spacing: 1px;
`

const LinkInNav = styled(Link)`
  ${styles}
  ${spacing}
`

export const ExternalLink = styled.a`
${styles}
${tightLetters}
${spacing}
`

export default LinkInNav