import React, {useState} from 'react'
import styled, { ThemeProvider } from 'styled-components'
import 'typeface-roboto'
import theme from '../theme'
import Box from './../../components/Box'
import DesktopLayout from './DesktopLayout'
import MobileLayout from './MobileLayout'
import useCookies from '../../hooks/useCookies'

const LayoutBase = styled.div`
  font-size: 12px;
  font-family: Roboto;
  @media (max-width: 960px){
    font-size: 14px;
  }
`

const Layout = ({children, shopPage}) => {
  useCookies()
  const [activeSlide, setActiveSlide] = useState(null)
  const onSlideChange = (newSlide)=>{
    setActiveSlide(newSlide)
  }

  return (
    <ThemeProvider theme={theme}>
      <LayoutBase>
        <Box display={['none','none','block']}>
          <DesktopLayout activeSlide={activeSlide} shopPage={shopPage}>
            {typeof children==='function' ? children({onSlideChange}) : {children}}
          </DesktopLayout>
        </Box>
        <Box display={['block','block','none']}>
          <MobileLayout>
            {typeof children==='function' ? children({onSlideChange}) : {children}}
          </MobileLayout>
        </Box>
      </LayoutBase>
    </ThemeProvider>
  )
}

export default Layout