import React from 'react'
import Box from '../../components/Box'
import LinkInNav from './LinkInNav'
import Group from './Group'
import { globalHistory } from '@reach/router'

const Navigation = ({items}) => {
  const path = globalHistory.location.pathname
  return (
    <Box>
      {items.map((item, i) => {
        if(item.type === 'page'){
          return <LinkInNav key={`${i}-nav-item`} pb={['16px', '16px', '20px']} active={!path || path === item.link} to={item.link}>
            {item.title}
          </LinkInNav>
        }
        if(item.type === 'group'){
          return <Group key={`${i}-nav-item`} pages={item.pages} groupTitle={item.groupTitle} />
        }}
      )}
    </Box>
  )
}

export default Navigation
