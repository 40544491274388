import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Box from './../../components/Box'
import Link from './../../components/Link'

const contactInfo = graphql`
query{
  contentfulContactData{
    headerName
  }
}
`
const HeaderDesk= styled(Box)`
  letter-spacing: 2px;
  position: fixed;
  left: 32px;
  top: 32px;
  color: #000;
  z-index: 10;
  text-decoration: none !important;
  font-weight: 500;
  font-size: 14px;
  `

const HeaderMob= styled(Box)`
  letter-spacing: 2px;
  text-decoration: none !important;
  font-weight: 500;
  font-size: 14px;
  `

const Header = () => {
  const contactData = useStaticQuery(contactInfo)
  return <Link style={{ color: 'black', textDecoration: 'none' }} activeStyle={{ color: 'black', textDecoration: 'none' }} to='/'>
    <HeaderDesk mb='32px' display={['none', 'none','block']} >
      {contactData.contentfulContactData.headerName}
    </HeaderDesk>
    <HeaderMob  display={['block', 'block','none']} >
      {contactData.contentfulContactData.headerName}
    </HeaderMob>
  </Link>
}

export default Header