import styled from 'styled-components/macro'
import Box from './Box'
import {flexbox} from '@material-ui/system'

const Flex = styled(Box)`
  ${props => props.letterSpacing ? `letter-spacing: ${props.letterSpacing}!important` : ''};
  ${props => props.roboto ? 'font-family: Roboto' : ''};
  ${flexbox};
`
Flex.defaultProps={
  display: 'flex'
}
export default Flex
