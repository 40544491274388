import React from 'react'
import Box from './../../components/Box'
import Flex from './../../components/Flex'
import Navigation from '../Navigation'
import Caption from '../Caption'
import ContactInfo from '../ContactInfo'
import Header from './Header'

const DesktopLayout = ({children, activeSlide, shopPage = false}) => {
  return (
    <Box>
      <Header />
      <Flex position='relative' justifyContent={shopPage ? 'flex-start' : 'space-between'}>
        <Flex 
          display={['none', 'none', 'flex']}
          flex={[undefined, undefined,'0 0 240px']}
          pt='130px'
          pl={[undefined, undefined,'32px']}
          flexDirection='column'
          justifyContent='space-between'>
          <Box position='fixed' height='100%'>
            <Box width='100%'>
              <Navigation />
            </Box>
            <Box position='absolute' bottom='270px'>
              <Caption image={activeSlide} />
            </Box>
            <ContactInfo position='absolute' bottom='200px' />
          </Box>
        </Flex>
        {children}
      </Flex>
    </Box>
  )
}

export default DesktopLayout