import styled from 'styled-components/macro'
import {
  spacing,
  display,
  sizing,
  positions
} from '@material-ui/system'

const Img = styled.img`
  width: 100%;
  ${spacing}
  ${display}
  ${sizing}
  ${positions}
`
export default Img