import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { consoleMessage } from '../config'

const initializeReactGA = () => {
  ReactGA.initialize(process.env.GA_UID)
  const url = window.location.pathname.split('/').slice(1).join('')
  ReactGA.pageview(url || '/')
}

const isCookieAccepted = ()=> document.cookie.split(';').some((cookie)=> cookie === 'cookieconsent_status=allow')

const useCookies = () => {
  const [isGaInitialized, setGaInitialized] = useState(false)

  useEffect(() => {
    console.log(consoleMessage, 'color: #bada55; background: #222;')
    setTimeout(() => {
      const handleAcceptCookies = (status) => {
        if (status === 'allow') {
          initializeReactGA()
          setGaInitialized(true)
          isGaInitialized && console.log('GA-initialized')
        }
      }
      window.cookieconsent && window.cookieconsent.initialise({
        'palette': {
          'popup': {
            'background': '#252e39'
          },
          'button': {
            'background': '#2b6bac'
          }
        },
        revokable: true,
        onStatusChange: handleAcceptCookies,
        'theme': 'classic',
        'type': 'opt-in'
      })
      if (isCookieAccepted()) {
        handleAcceptCookies('allow')
      }
    }, 3000)
  }, [])
}


export default useCookies
